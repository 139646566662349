import { useAnalyticsStore } from "~/store/useAnalyticsStore";

export default defineNuxtRouteMiddleware((to, from) => {
  const { resetFilters } = useAnalyticsStore();

  const toParts = to.path.split("/");
  const fromParts = from.path.split("/");

  // TODO: for now doing this like resetting for all except overview
  if (toParts[2] === "overview" && fromParts[2] === toParts[2]) {
    console.log("==> do not reset filters");
  } else {
    console.log("==> reset filters");
    resetFilters();
  }

  // Helper function to check if a path is in the 'overview' section
  // const isInOverview = (pathParts) => {
  //   return (
  //     pathParts[2] === "overview" ||
  //     (pathParts[2] === "collection" && pathParts[4] === "overview")
  //   );
  // };

  // Only reset filters if we are moving out of the overview section
  // or changing to a different collection's overview
  // if (
  //   !isInOverview(toParts) ||
  //   (isInOverview(toParts) && !isInOverview(fromParts)) ||
  //   (toParts[2] === "collection" && fromParts[4] !== toParts[4])
  // ) {
  //   console.log("==> reset filters");
  //   resetFilters();
  // } else {
  //   console.log("==> do not reset filters");
  // }
});
